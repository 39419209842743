import React, { Suspense, useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
import Header from 'features/Header/Header';
import Footer from 'features/Footer/Footer';
import HomePage from 'features/HomePage/HomePage';
import ContactsPage from 'features/ContactsPage/ContactsPage';
import PricePage from 'features/PricePage/PricePage';
import SalesPage from 'features/SalesPage/SalesPage';
import ReviewsPage from 'features/ReviewsPage/ReviewsPage';
import NotFound from 'features/Errors/NotFound';
import { ViewportProvider } from 'app/viewportContext';
import Spinner from 'shared/Spinner';
import ModalPopup from 'shared/ModalPopup';
import QuestionForm from 'shared/QuestionForm';
import ServicesPage from 'features/Services/ServicesPage';
import SharkoPage from 'features/Services/SharkoPage/SharkoPage';
import EmcPage from 'features/Services/EmcPage/EmcPage';
import CollariumPage from 'features/Services/CollariumPage/CollariumPage';
import VisagePage from 'features/Services/VisagePage/VisagePage';
import CosmetologyPage from 'features/Services/CosmetologyPage/CosmetologyPage';
import HairdressPage from 'features/Services/HairdressPage/HairdressPage';
import ManicurePage from 'features/Services/ManicurePage/ManicurePage';
import MassagePage from 'features/Services/MassagePage/MassagePage';
import JapanMassagePage from 'features/Services/JapanMassagePage/JapanMassagePage';
import EndospherePage from 'features/Services/EndospherePage/EndospherePage';
import GdprModal from 'features/Modals/GdprModal';
import InfoPage from 'features/InfoPage/InfoPage';

function App() {
  const [buttonUp, setButtonUp] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [paddingTop, setPaddingtop] = useState(0);
  const [personId, setPersonId] = useState(null);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    } );
  };
  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    setScrollPosition(scrolled);
    if (scrolled > 600) { 
      setButtonUp(true);
    }  
    else if (scrolled <= 600) { 
      setButtonUp(false);
    } 
  }; 
  window.addEventListener('scroll', toggleVisible); 

  const openPopup = (id = null) => {
    setShowPopup(true);
    setPersonId(id);
  };

  return (
    <BrowserRouter>
      <ViewportProvider>
        <div className='App page-wrap' style={{paddingTop: paddingTop}}>
          <YMInitializer accounts={[97486562]} options={{
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          }} />
          <Header setPaddingtop={setPaddingtop} />
          <Suspense fallback={<Spinner/>}>
            <Routes>
              <Route path="/" element={<HomePage openPopup={openPopup}/>} />
              <Route path="/uslugi" element={<ServicesPage />} />
              <Route path="/uslugi/dush-sharko" element={<SharkoPage />} />
              <Route path="/uslugi/trenirovki-ems" element={<EmcPage />} />
              <Route path="/uslugi/solyaryi-s-vitaminom-d" element={<CollariumPage />} />
              <Route path="/uslugi/visage" element={<VisagePage />} />
              <Route path="/uslugi/kosmetologiya" element={<CosmetologyPage />} />
              <Route path="/uslugi/parikmaherskie-uslugi" element={<HairdressPage />} />
              <Route path="/uslugi/manikyur-i-pedikyur" element={<ManicurePage />} />
              <Route path="/uslugi/massage" element={<MassagePage />} />
              <Route path="/uslugi/yaponskyi-massag-dly-volos-i-golovy" element={<JapanMassagePage />} />
              <Route path="/uslugi/endosfera" element={<EndospherePage />} />
              <Route path="/ceny" element={<PricePage openPopup={openPopup}/>} />              
              <Route path="/akcii" element={<SalesPage/>} />
              <Route path="/otzyvy" element={<ReviewsPage/>} />
              <Route path="/kontakty" element={<ContactsPage openPopup={openPopup}/>} />
              <Route path="/informaciya" element={<InfoPage/>} />
              <Route path='*' element={<NotFound />}/>
            </Routes>
          </Suspense>
          <Footer/>
          <GdprModal/>
          {buttonUp && <button 
                          id="scroll_top" 
                          className={`scroll_top`} 
                          onClick={scrollTop}>&nbsp;</button>
          }
          {showPopup && <ModalPopup setShow={setShowPopup} addedClass="entry-popup">
            <QuestionForm setShow={setShowPopup} personId={personId}/>
          </ModalPopup>}
        </div>
      </ViewportProvider>
    </BrowserRouter>
  );
}

export default App;
