import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetEndospherePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './endosphere.style.scss';
const faceImg = require("assets/img/endosphere_face.jpg");
const bodyImg = require("assets/img/endosphere_body.jpg");

const EndospherePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetEndospherePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/endosfera');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="endosphere service-page">
            <LoadingBlock isLoading={isLoading}>
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Эндосфера в салоне Граф Ягода'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Эндосфера - аппаратная косметическая процедура для тела и лица. Используются разные манипулы для тела и лица.'}
                        />
                        <section className="endosphere_intro">
                            <div className="container">
                                <Breadcrumbs pageName={data?.title} isService = {true}/>
                                <h1>{data?.title}</h1>
                                <div className="endosphere_intro__row row">
                                    {data?.first_block_photo && <div className="endosphere_intro__left">
                                        <img src={data?.first_block_photo} alt="Эндосфера в салоне Граф Ягода" title="Эндосфера" />
                                    </div>}
                                    <div className="endosphere_intro__right">
                                        <div className="endosphere_intro__block">
                                            {data?.first_block_first_text && parse(data?.first_block_first_text)}
                                        </div>
                                        <div className="endosphere_intro__billet billet">
                                            {data?.first_block_second_text && parse(data?.first_block_second_text)}
                                        </div>
                                        <div className="endosphere_intro__text">
                                            {data?.first_block_third_text && parse(data?.first_block_third_text)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                        <section className="endosphere_face">
                            <div className="container">
                                <div className="endosphere_face__row row">
                                    <div className="endosphere_face__text">
                                        <div className="endosphere_face__billet billet">
                                            <h2>Эндосфера для лица</h2>
                                            <p>С помощью эндосферы для лица можно устранить следующие проблемы:</p>
                                        </div>
                                        <ul className="endosphere_face__list">
                                            <li>Восстановить естественный тонус мышц и тканей лица;</li>
                                            <li>Уменьшить возрастные изменения кожи;</li>
                                            <li>Вернуть тонус области вокруг глаз;</li>
                                            <li>Избавиться от отёков;</li>
                                            <li>Нормализовать цвет лица;</li>
                                            <li>Убрать морщины на лбу;</li>
                                            <li>Скорректировать овал лица;</li>
                                            <li>Устранить мешки под глазами;</li>
                                            <li>Убрать складки в носогубной зоне;</li>
                                            <li>Вернуть упругость кожи и тонус мышц щёк и скул</li>
                                            <li>Расщепить жировые клетки;</li>
                                            <li>Вернуть здоровый цвет кожи;</li>
                                        </ul>
                                    </div>
                                    <div className="endosphere_face__img">
                                        <img src={faceImg} alt="Эндосфера для лица" />
                                    </div>
                                </div>
                                <div className="endosphere_preffs">
                                    <h2>Преимущества эндосферы для лица перед другими аппаратами:</h2>
                                    <ul className="endosphere_preffs__list">
                                        <li>Ощутимый эффект без хирургического вмешательства</li>
                                        <li>Отсутствие болезненных ощущений и необходимости анестезии</li>
                                        <li>Более низкая стоимость по сравнению с другими косметологическими процедурами</li>
                                        <li>Заметный результат после курса процедур и возможность поддерживать его с помощью поддерживающей терапии</li>
                                        <li>Запуск естественного синтеза коллагена и возвращение молодости и цветущего внешнего вида кожи</li>
                                    </ul>
                                </div>
                            </div>
                        </section>

                        <section className="endosphere_how">
                            <div className="container">
                                <div className="endosphere_how__header">
                                    <h2>Как проходит процедура</h2>
                                </div>
                                <div className="endosphere_how__billet billet">
                                    <p>Перед процедурой эндосфера-терапии пациент ложится на кушетку</p>
                                    <p>Врач очищает лицо от макияжа, настраивает аппарат и начинает обрабатывать лицо, особенно тщательно проходясь 
                                    по проблемным зонам. Процедура длится 40–60 минут, после чего врач наносит успокаивающую сыворотку или крем по типу кожи</p>
                                </div>
                            </div>
                        </section>

                        <section className="endosphere_body">
                            <div className="container">
                                <div className="endosphere_body__row row">
                                    <div className="endosphere_body__img">
                                        <img src={bodyImg} alt="Эндосфера для тела" />
                                    </div>
                                    <div className="endosphere_body__text">
                                        <div className="endosphere_body__billet billet">
                                            <h2>Эндосфера для тела</h2>
                                        </div>
                                        <div className="endosphere_body__content">
                                            <p>Процедура популярна не только среди женщин, но и среди мужчин. Методика способствует уменьшению 
                                            локальных жировых отложений и повышению тонуса кожи. </p>
                                            <p>В зависимости от зоны воздействия, типа кожи и возраста необходимо пройти курс из 6-12 сеансов. 
                                            Эффект от курсовой процедуры может сохраняться до 6 месяцев.</p>
                                            <p>Для поддержания результата рекомендуется каждую неделю проходить процедуру аппаратного массажа.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="endosphere_zones">
                            <div className="container">
                                <h2>{data?.second_block_title}</h2>
                                <p className="endosphere_zones_sub">{data?.second_block_list_subtitle}</p>
                                <ul className="endosphere_zones__list">
                                    <li>{data?.second_block_list_first_item}</li>
                                    <li>{data?.second_block_list_second_item}</li>
                                    <li>{data?.second_block_list_third_item}</li>
                                    <li>{data?.second_block_list_fourth_item}</li>
                                    <li>{data?.second_block_list_fifth_item}</li>
                                </ul>
                                <div className="endosphere_zones__text">
                                    {data?.second_block_text && parse(data?.second_block_text)}
                                </div>
                            </div>
                        </section>

                        <section className="endosphere_indications">
                            <div className="container">
                                <div className="endosphere_indications__row row">
                                    <div className="endosphere_indications__text">
                                        <h2>{data?.third_block_title}</h2>
                                        <p>{data?.third_block_list_subtitle}</p>
                                    </div>
                                    <div className="endosphere_indications__billet billet">
                                        <ul className="endosphere_indications__list">
                                            <li>{data?.third_block_list_first_item}</li>
                                            <li>{data?.third_block_list_second_item}</li>
                                            <li>{data?.third_block_list_third_item}</li>
                                            <li>{data?.third_block_list_fourth_item}</li>
                                            <li>{data?.third_block_list_fifth_item}</li>
                                            <li>{data?.third_block_list_sixth_item}</li>
                                            <li>{data?.third_block_list_seventh_item}</li>
                                            <li>{data?.third_block_list_eighth_item}</li>
                                            <li>{data?.third_block_list_ninth_item}</li>
                                            <li>{data?.third_block_list_tenth_item}</li>
                                            <li>{data?.third_block_list_eleventh_item}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото процесса и результат</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default EndospherePage;