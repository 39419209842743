import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetMassagePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';
import ServiceGallery from 'shared/ServiceGallery';
import parse from 'html-react-parser';
import './massage.style.scss';
const honeyImg = require("assets/img/massage_honey.jpg");
const sportImg = require("assets/img/massage_sport.jpg");

const MassagePage = () => {
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetMassagePageTextQuery();

    useEffect(() => {
        ym('hit', '/uslugi/massage');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);

    return (
        <div className="massage service-page">
            <LoadingBlock isLoading={isLoading} >
                {isError
                    ? <p>Ошибка получения данных</p>
                    : <>
                        <HeadTags
                            title={data && data.seo?.title ? data.seo.title : 'Массаж'}
                            metaDescription={data && data.seo?.description ? data.seo.description : 'Массаж'}
                        />
                        <div className="container">
                            <Breadcrumbs pageName={data?.title} isService = {true}/>
                            <h1>{data?.title}</h1>
                        </div>
                        <section className="massage_manual">
                            <div className="container">
                                <div className="massage_row row">
                                    <div className="massage_row__text">
                                        <div className="massage_row__billet billet">
                                            <h2>{data?.first_block_title}</h2>
                                            <div className="paragraph-decor">{data?.first_block_first_text && parse(data?.first_block_first_text)}</div>
                                        </div>
                                        <div className="massage_row__block">{data?.first_block_second_text && parse(data?.first_block_second_text)}</div>
                                    </div>
                                    {data?.first_block_photo && <div className="massage_row__img">
                                        <img src={data?.first_block_photo} alt="Ручной массаж" title="Ручной массаж"/>
                                    </div>}
                                </div>
                            </div>
                        </section>
                        <section className="massage_honey">
                            <div className="container">
                                <div className="massage_row massage_row--right row">
                                    <div className="massage_row__img">
                                        <img src={honeyImg} alt="Медовый массаж" />
                                    </div>
                                    <div className="massage_row__text">
                                        <div className="massage_row__billet billet">
                                            <h2>Медовый массаж</h2>
                                            <div className="paragraph-decor">
                                                <p style={{lineHeight:'1.45em'}}>Медовый массаж — это не просто уход за телом, это настоящее волшебство для вашей кожи! 
                                                Это коррекционная процедура с заметным лифтинг-эффектом.<br/>
                                                Благодаря уникальным свойствам меда, он улучшает кровообращение, очищает от токсинов и дарит невероятную упругость. 
                                                Уже через несколько сеансов вы заметите, как кожа становится гладкой, бархатной, а тело — стройным и подтянутым</p>
                                            </div>
                                        </div>
                                        <div className="massage_row__block">
                                            <p style={{lineHeight:'1.75em'}}>Чтобы иметь красивое и здоровое тело, нужно заботиться о себе 2-3 раза в неделю. Это не просто процедура, 
                                                а акт любви к себе! Уход за собой — это наслаждение, радость и счастье<br/>
                                                Только счастливый человек может делиться этой любовью с близкими.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="massage_hardware">
                            <div className="container">
                                <div className="massage_row row">
                                    <div className="massage_row__text">
                                        <div className="massage_row__billet billet">
                                            <h2>{data?.second_block_title}</h2>
                                            <div className="paragraph-decor">{data?.second_block_text && parse(data?.second_block_text)}</div>
                                        </div>
                                        <div className="massage_row__block">
                                            <p>{data?.second_block_list_title}</p>
                                            <ul className="massage_row__list">
                                                <li>{data?.second_block_list_first_item}</li>
                                                <li>{data?.second_block_list_second_item}</li>
                                                <li>{data?.second_block_list_third_item}</li>
                                                <li>{data?.second_block_list_fourth_item}</li>
                                                <li>{data?.second_block_list_fifth_item}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {data?.second_block_photo && <div className="massage_row__img">
                                        <img src={data?.second_block_photo} alt="Аппаратный массаж" title="Аппаратный массаж"/>
                                    </div>}
                                </div>
                            </div>
                        </section>
                        <section className="massage_sport">
                            <div className="container">
                                <div className="massage_row massage_row--right row">
                                    <div className="massage_row__img">
                                        <img src={sportImg} alt="Спортивный массаж" />
                                    </div>
                                    <div className="massage_row__text">
                                        <div className="massage_row__billet billet">
                                            <h2>Спортивный массаж</h2>
                                            <div className="paragraph-decor">
                                                <p>Спортивный массаж подойдёт всем, кто занимается спортом или ведёт активный образ жизни. Он будет 
                                                    полезен профессиональным спортсменам и спортсменам-любителям, так как помогает повысить физическую 
                                                    выносливость, снизить утомляемость и болевые ощущения, а также способствует быстрому восстановлению 
                                                    после тренировок и соревнований</p>
                                            </div>
                                        </div>
                                        <div className="massage_row__block">
                                            <ul className="massage_row__list">
                                                <li>Положительное воздействие на нервно-мышечный аппарат;</li>
                                                <li>Улучшение крово- и лимфообращения;</li>
                                                <li>Стимуляция обменных процессов в организме;</li>
                                                <li>Насыщение мышц кислородом и питательными веществами;</li>
                                                <li>Помощь в освобождении мышц от шлаков и токсинов, в частности от молочной кислоты;</li>
                                                <li>Повышение эластичности связок;</li>
                                                <li>Улучшение состояния суставов и окружающих их тканей;</li>
                                                <li>Расслабление, успокоение и снижение уровня гормона стресса — кортизола;</li>                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="massage_therapy">
                            <div className="container">
                                <h2>{data?.third_block_title}</h2>
                                <div className="massage_therapy__row row">
                                    {data?.third_block_photo && <div className="massage_therapy__img">
                                        <img src={data?.third_block_photo} alt="Прессотерапия" title="Прессотерапия"/>
                                    </div>}
                                    <div className="massage_therapy__text">{data?.third_block_first_text && parse(data?.third_block_first_text)}</div>
                                    <div className="massage_therapy__block">
                                        <div className="paragraph-decor">{data?.third_block_second_text && parse(data?.third_block_second_text)}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {
                            data?.gallery_images.length ? ( 
                                <div className="service_gallery">
                                    <h2>Фото массажа, расслабление и оздоровление.</h2>                                                                   
                                    <ServiceGallery slides ={data.gallery_images} name={data?.title} />   
                                </div>                             
                            ) : null
                        }
                    </>
                }
            </LoadingBlock>
        </div >
    );
};
export default MassagePage;