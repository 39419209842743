import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import { useGetEmcPageTextQuery, useGetCollariumPageTextQuery, useGetSharkoPageTextQuery, 
    useGetVisagePageTextQuery, useGetCosmetologyPageTextQuery, useGetHairdressPageTextQuery,
    useGetManicurePageTextQuery, useGetMassagePageTextQuery,
    useGetEndospherePageTextQuery, useGetJapanMassagePageTextQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import Breadcrumbs from 'shared/Breadcrumbs';

const ServicesPage = () => {
    const location = useLocation();
    const { data: emcData, isLoading: emcLoading } = useGetEmcPageTextQuery();
    const { data: visageData, isLoading: visageLoading } = useGetVisagePageTextQuery();
    const { data: sharkoData, isLoading: sharkoLoading } = useGetSharkoPageTextQuery();
    const { data: collariumData, isLoading: collariumLoading } = useGetCollariumPageTextQuery();
    const { data: cosmetologyData, isLoading: cosmetologyLoading } = useGetCosmetologyPageTextQuery();
    const { data: hairdressData, isLoading: hairdressLoading } = useGetHairdressPageTextQuery();
    const { data: manicureData, isLoading: manicureLoading } = useGetManicurePageTextQuery();
    const { data: massageData, isLoading: massageLoading } = useGetMassagePageTextQuery();
    const { data: endosphereData, isLoading: endosphereLoading } = useGetEndospherePageTextQuery();
    const { data: japanMassageData, isLoading: japanMassageLoading } = useGetJapanMassagePageTextQuery();
    const [services, setServices] = useState([]);
    const [show, setShow] = useState(false);
    const [sortServices, setSortServices] = useState([]);

    useEffect(() => {
        if (!emcLoading && !visageLoading && !sharkoLoading && !collariumLoading && !cosmetologyLoading && !hairdressLoading && !manicureLoading && !massageLoading && !endosphereLoading && !japanMassageLoading) {
            setSortServices(services.sort((a, b) => a.position - b.position));
        }
    }, [emcLoading, visageLoading, sharkoLoading, collariumLoading, cosmetologyLoading, hairdressLoading, manicureLoading, massageLoading, endosphereLoading, japanMassageLoading, services]);

    useEffect(() => {
        if (sortServices.length) {
           setShow(true); 
        }
    }, [sortServices]);

    useEffect(() => {
        ym('hit', '/uslugi');
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [location]);

    useEffect(() => {
        if (visageData) {
            setServices(state => [...state, {...visageData, url: 'visage', position: 9}]);
        }
    }, [visageData]);
    useEffect(() => {
        if (emcData) {
            setServices(state => [...state, {...emcData, url: 'trenirovki-ems', position: 8}]);
        }
    }, [emcData]);
    useEffect(() => {
        if (sharkoData) {
            setServices(state => [...state, {...sharkoData, url: 'dush-sharko', position: 7}]);
        }
    }, [sharkoData]);
    useEffect(() => {
        if (collariumData) {
            setServices(state => [...state, {...collariumData, url: 'solyaryi-s-vitaminom-d', position: 5}]);
        }
    }, [collariumData]);
    useEffect(() => {
        if (cosmetologyData) {
            setServices(state => [...state, {...cosmetologyData, url: 'kosmetologiya', position: 4}]);
        }
    }, [cosmetologyData]);
    useEffect(() => {
        if (hairdressData) {
            setServices(state => [...state, {...hairdressData, url: 'parikmaherskie-uslugi', position: 3}]);
        }
    }, [hairdressData]);
    useEffect(() => {
        if (manicureData) {
            setServices(state => [...state, {...manicureData, url: 'manikyur-i-pedikyur', position: 6}]);
        }
    }, [manicureData]);
    useEffect(() => {
        if (massageData) {
            setServices(state => [...state, {...massageData, url: 'massage', position: 2}]);
        }
    }, [massageData]);
    useEffect(() => {
        if (endosphereData) {
            setServices(state => [...state, {...endosphereData, url: 'endosfera', position: 1}]);
        }
    }, [endosphereData]);
    useEffect(() => {
        if (japanMassageData) {
            setServices(state => [...state, {...japanMassageData, url: 'yaponskyi-massag-dly-volos-i-golovy', position: 0}]);
        }
    }, [japanMassageData]);

    return (
        <div className="allservice">
            <HeadTags
                title='Услуги салона красоты в Брянске'
                metaDescription='Каталог услуг'
            />
            <div className="container">
                <Breadcrumbs pageName={'Каталог услуг'}/>
                <h1>Каталог услуг</h1>
                <LoadingBlock isLoading={!show}>
                    {sortServices.length > 0
                        ? <ul className="services-list">
                            {sortServices.map(el => el.is_published && <li className="services-item" key={el.title}>
                                <Link to={`/uslugi/${el.url}`}>
                                    <span className="services-item_img">
                                        <img src={el.photo_in_list_services} alt={el.title_in_list_services} title={el.title_in_list_services} />
                                    </span>
                                    <span className="services-item_name">{el.title_in_list_services}</span>
                                </Link>
                            </li>)}
                        </ul>
                        : <></>
                    }
                </LoadingBlock>
            </div>
        </div>
    );
};
export default ServicesPage;