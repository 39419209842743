import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeadTags from "shared/HeadTags";
import ym from "react-yandex-metrika";
import LoadingBlock from "shared/LoadingBlock";
import Breadcrumbs from "shared/Breadcrumbs";
import { useGetSalesListQuery } from "services/api/api";
import parse from "html-react-parser";
import "./sales.style.scss";

const SalesPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetSalesListQuery();  

  useEffect(() => {
    ym("hit", "/akcii");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  return (
    <div className="sales">
      <HeadTags
        title='Акции салона красоты "Граф Ягода"'
        metaDescription='Акции салона красоты "Граф Ягода"'
      />
      <div className="container">
        <Breadcrumbs pageName={"Акции"} />
        <h1>Акции</h1>
        <LoadingBlock isLoading={isLoading}>
          <ul className="sales_list">
            {data ? (
              data.length ? (
                <>
                  {data.map((el, i) => {
                    if (el.is_published) {
                      return (
                        <li key={i} className={`sales_elem ${el.is_archive ? 'sales_elem--arhive': ''}`}>
                          <div className="sales_elem__image">
                            <img src={el.image} alt={el.title} />
                          </div>
                          <div className="sales_elem__text">
                            <h2 className="sales_elem__title">{el.title}</h2>
                            {parse(el.description)}
                          </div>
                        </li>
                      );
                    }
                  })}
                </>
              ) : null
            ) : (
              <p>Загрузка ...</p>
            )}
          </ul>
        </LoadingBlock>
      </div>
    </div>
  );
};

export default SalesPage;
