import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Category, CategoryStore, Service, ServicesList, ServicesMap, EmployeeItem, Gallery, Certificate, Review, Promotion,
  MainPage, SharkoPage, EmcPage, CollariumPage, VisagePage, 
  CosmetologyPage, HairdressPage, ManicurePage, MassagePage, JapanMassagePage, EndospherePage } from './types/DataTypes';
import { FormRequest } from './types/FormRequest';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getCategory: builder.query<CategoryStore, void>({
      query: () => `categories/`,
      transformResponse: (response: Category[]) => {
        let obj: CategoryStore['categoriesMap']  = {};
        const createMap = (el: Category) => {
          obj[el.id] = el;
          el.children.forEach(el => createMap(el));
        };
        response.forEach(createMap);
        return {
          categories: response,
          categoriesMap: obj
        };
      }
    }),
    getServicesList: builder.query<ServicesMap[], void>({
      query: () => `services/`,
      transformResponse: (response: ServicesList) => {
        const res = response.results;
        let obj: ServicesMap[] = [];
        const createMap = (el: Service) => {
          const serviceMap = obj.find(item => item.categoryId === el.category.id);
          if (serviceMap) {
            serviceMap.services.push(el);
          } else {
            obj.push({
              categoryId: el.category.id,
              parentCategoryId: el.category.parent,
              categoryName: el.category.name,
              services: [el]
            });
          }
        };
        res.forEach(el => createMap(el));
        return obj;
      }
    }),
    getEmployeesList: builder.query<EmployeeItem[], void>({
      query: () => `employees/`,
    }),
    getEmployeeById: builder.query<EmployeeItem, string>({
      query: (slug) => `employees/${slug}/`,
    }),
    getCertificatesList: builder.query<Certificate[], void>({
      query: () => `certificates/`,
    }),
    getGalleryList: builder.query<Gallery[], void>({
      query: () => `main-page-gallery/`,
    }),
    getMainPageText: builder.query<MainPage, void>({
      query: () => `main_page_texts/`,
      transformResponse: (response: MainPage[]) => {
        return response[0]
      }
    }),
    getSharkoPageText: builder.query<SharkoPage, void>({
      query: () => `shower/`,
      transformResponse: (response: SharkoPage[]) => {
        return response[0]
      }
    }),
    getEmcPageText: builder.query<EmcPage, void>({
      query: () => `emstraining/`,
      transformResponse: (response: EmcPage[]) => {
        return response[0]
      }
    }),
    getCollariumPageText: builder.query<CollariumPage, void>({
      query: () => `collarium/`,
      transformResponse: (response: CollariumPage[]) => {
        return response[0]
      }
    }),
    getVisagePageText: builder.query<VisagePage, void>({
      query: () => `visage/`,
      transformResponse: (response: VisagePage[]) => {
        return response[0]
      }
    }),
    getCosmetologyPageText: builder.query<CosmetologyPage, void>({
      query: () => `cosmetology/`,
      transformResponse: (response: CosmetologyPage[]) => {
        return response[0]
      }
    }),
    getHairdressPageText: builder.query<HairdressPage, void>({
      query: () => `hairdressing/`,
      transformResponse: (response: HairdressPage[]) => {
        return response[0]
      }
    }),
    getManicurePageText: builder.query<ManicurePage, void>({
      query: () => `manicure/`,
      transformResponse: (response: ManicurePage[]) => {
        return response[0]
      }
    }),
    getMassagePageText: builder.query<MassagePage, void>({
      query: () => `massage/`,
      transformResponse: (response: MassagePage[]) => {
        return response[0]
      }
    }),
    getJapanMassagePageText: builder.query<JapanMassagePage, void>({
      query: () => `japan-massage/`,
      transformResponse: (response: JapanMassagePage[]) => {
        return response[0]
      }
    }),
    getEndospherePageText: builder.query<EndospherePage, void>({
      query: () => `endosphere/`,
      transformResponse: (response: EndospherePage[]) => {
        return response[0]
      }
    }),
    getReviewsList: builder.query<Review[], void>({
      query: () => `reviews/`,
    }),
    createReviewFormRequest: builder.mutation<Review, { body: Review}>({
      query: ({body}) => ({
        url: `reviews/`,
        method: 'POST',
        body,
      })
    }),
    createFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `appointments/`,
        method: 'POST',
        body,
      })
    }),
    getSalesList: builder.query<Promotion[], void>({
      query: () => `promotions/`,
    }),
  }),
});

export const { 
  useGetCategoryQuery, useGetServicesListQuery,
  useGetEmployeesListQuery, useGetEmployeeByIdQuery,
  useGetGalleryListQuery, useGetCertificatesListQuery,
  useGetReviewsListQuery, useCreateReviewFormRequestMutation,
  useCreateFormRequestMutation,
  useGetMainPageTextQuery, useGetSharkoPageTextQuery, useGetEmcPageTextQuery, useGetCollariumPageTextQuery,
  useGetVisagePageTextQuery, useGetCosmetologyPageTextQuery, useGetHairdressPageTextQuery, useGetManicurePageTextQuery,
  useGetMassagePageTextQuery, useGetEndospherePageTextQuery, useGetJapanMassagePageTextQuery, useGetSalesListQuery
} = api;