import { NavLink } from 'react-router-dom';

const Menu = () => {
  return (
    <ul className="menu" itemScope itemType="http://schema.org/SiteNavigationElement">
      <li><NavLink to="/" itemProp="url">Главная</NavLink></li>
      <li>
        <NavLink to="/uslugi" itemProp="url">Наши услуги</NavLink>
        <div className="menu_dropdown">
          <ul>
            <li><NavLink to="/uslugi/yaponskyi-massag-dly-volos-i-golovy" itemProp="url">SPA RELAX процедура</NavLink></li>
            <li><NavLink to="/uslugi/endosfera" itemProp="url">Эндосферотерапия:<br/> - для лица<br/> - для тела</NavLink></li>
            <li><NavLink to="/uslugi/massage" itemProp="url">Массаж:<br/> - ручной<br/> - Аппаратный и LPG-массаж<br/> - спортивный массаж</NavLink></li>
            <li><NavLink to="/uslugi/parikmaherskie-uslugi" itemProp="url">Парикмахерские услуги<br/> и уход за волосами</NavLink></li>
            <li><NavLink to="/uslugi/kosmetologiya" itemProp="url">Косметология:<br/> - Эстетическая Косметология<br/> - аппаратная Косметология</NavLink></li>
            <li><NavLink to="/uslugi/solyaryi-s-vitaminom-d" itemProp="url">Солярий-коллариум</NavLink></li>
            <li><NavLink to="/uslugi/manikyur-i-pedikyur" itemProp="url">Маникюр и педикюр</NavLink></li>
            <li><NavLink to="/uslugi/dush-sharko" itemProp="url">Коррекция фигуры под водоЙ:<br/> - Душ Шарко<br/> - гидромассаж</NavLink></li>
          </ul>
        </div>
      </li>
      <li><NavLink to="/ceny" itemProp="url">Цены</NavLink></li>
      <li><NavLink to="/akcii" itemProp="url">Акции</NavLink></li>
      <li><NavLink to="/otzyvy" itemProp="url">Отзывы</NavLink></li>
      <li><NavLink to="/informaciya" itemProp="url">Информация</NavLink></li>
      <li><NavLink to="/kontakty" itemProp="url">Контакты</NavLink></li>
    </ul>
  );
};

export default Menu;